<template>
  <div class="container flex flex-col h-full p-4">
    <div class="w-full flex items-center justify-center mt-6 mb-12">
      <span
        class="txt-grey-900 text-3xl font-bold mt-1 leading-none text-pris uppercase"
        style="color: #20409b"
        >{{ $t("Lịch làm việc") }}</span
      >
    </div>
    <AppointmentModal
      v-loading="isLoading"
      :doctorId="$user.doctor && $user.doctor.id"
      :items="appointmentsData"
      :metaData="appointmentMetaData"
      @setFilters="setFiltersAppointment"
      :refreshFilters="refreshFilters"
      @refresh="onRefresh"
      @onChangePagination="handleChangePagination"
    />
  </div>
</template>

<script>
import { APPOINTMENT_STATUS } from '@/utils/constants'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapActions, mapGetters } from 'vuex'
import AppointmentModal from './AppointmentModal'
import SearchAppointmentData from './SearchAppointmentData'

export default {
  name: 'WorkSchedules',
  components: {
    SearchAppointmentData,
    AppointmentModal
  },
  data () {
    return {
      isLoading: false,
      limit: 20,
      defaultFilter: {
        fromDate: window.moment().format('YYYY-MM-DD'),
        toDate: window.moment().format('YYYY-MM-DD'),
        status: APPOINTMENT_STATUS.CODE.CLINIC_PENDING
      },
      searchInput: '',
      searchResult: [],
      searchMetaResult: null,
      appointmentsData: [],
      appointmentMetaData: null,
      appointmentFilterParams: null,
      isRefreshFilter: false,
      timer: null,
      APPOINTMENT_STATUS,
      nextPage: 1,
      doctorId: null,
      page: 1
    }
  },
  watch: {
    searchData (data) {
      if (data) {
        if (this.nextPage > 1) {
          this.searchResult = [...this.searchResult, ...data]
          return
        }

        this.searchResult = data
      }
    },
    searchAllMeta (data) {
      if (data) {
        this.searchMetaResult = data
      }
    },
    appointments (data) {
      if (data) {
        this.appointmentsData = data
      }
    },
    appointmentMeta (data) {
      if (data) {
        // const _tempAppointmentMetaData = {
        //   ...data,
        //   // count: this.appointmentMeta?.count || 0,
        //   // total: this.appointmentMeta?.total || 0,
        //   // last_page: this.appointmentMeta?.last_page || 0
        //   // last_page: !this.appointmentsData?.length
        //   //   ? 0
        //   //   : this.appointmentsData?.length > Number(data.per_page)
        //   //     ? Math.ceil(this.appointmentsData?.length / data.per_page)
        //   //     : 1
        // }
        this.appointmentMetaData = data
      }
    },
    'appointmentFilterParams.doctor' (value) {
      if (value) this.getConsultingRooms(value)
      else this.getListConsultingRooms()
    }
  },
  created () {
    const self = this
    self.appointmentFilterParams = self.defaultFilter
    self.appointmentFilterParams.doctor = this.$user.doctor.id

    if (this.$user.doctor && this.$user.doctor?.id) {
      self.doctorId = this.$user.doctor.id
    }
    // self.getAppointments({ limit: self.limit, params: { doctor_id: self.doctorId } })

    self.timer = setInterval(function () {
      self.getAppointments({
        limit: self.limit,
        page: self.page,
        params: self.appointmentFilterParams
      })
    }, 1000 * 30) // refetch 30s
  },
  computed: {
    ...mapGetters({
      searchData: 'appointmentStore/searchAllData',
      searchAllMeta: 'appointmentStore/searchAllMeta',
      appointments: 'appointmentStore/allAppointments',
      appointmentMeta: 'appointmentStore/allAppointmentMeta'
    }),
    refreshFilters () {
      if (this.isRefreshFilter) {
        this.appointmentFilterParams = null
      }

      return this.isRefreshFilter
    },
    noMore () {
      return this.nextPage >= (this.searchMetaResult?.last_page || 1)
    },
    disabled () {
      return this.isLoading || this.noMore
    }
  },
  mounted () {
    window.scroll(0, 0)
    this.getAppointmentData()
  },
  methods: {
    ...mapActions('appointmentStore', [
      'getSearchAllRequest',
      'getAppointmentRequest',
      'checkInUser'
    ]),
    ...mapActions('consultingRoomStore', [
      'getConsultingRoomsByDoctorID',
      'getAllConsultingRooms'
    ]),
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    getAppointmentData () {
      this.isRefreshFilter = false
      this.appointmentFilterParams = this.defaultFilter
      this.getAppointments({ params: this.appointmentFilterParams })
    },
    async getListConsultingRooms () {
      try {
        await this.getAllConsultingRooms({
          params: {
            clinic_id: this.$globalClinic?.id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getConsultingRooms (doctor_id) {
      try {
        let paramsData = {}
        if (doctor_id !== this.$user?.doctor?.id) {
          paramsData.clinic_id = this.$globalClinic?.id
        }
        await this.getConsultingRoomsByDoctorID({
          id: doctor_id,
          params: paramsData
        })
      } catch (error) {
        console.log(error)
      }
    },
    hideModal () {
      this.isRefreshFilter = true

      this.onRefresh()
    },
    setParamsData ({ limit = 10, page = 1, data }) {
      let params = {
        limit,
        sort: 'start_time',
        sort_by: 'asc',
        page
      }

      if (data?.search?.length) {
        params.search = data.search
      }

      if (
        (data?.fromDate && !data?.toDate) ||
        (!data?.fromDate && data?.toDate)
      ) {
        return
      }

      if (data?.fromDate && data?.toDate) {
        params.start_time = this.moment(data?.fromDate).startOf('day').toDate()
        params.end_time = this.moment(data?.toDate).endOf('day').toDate()
      }

      if (Number(data?.room)) {
        params.cr_id = Number(data.room)
      }

      if (Number(data?.payment) > 0) {
        params.invoice_status = Number(data.payment)
      }

      // if (Number(data?.status) === APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED) {
      //   params.multi_status = APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED
      //   params.examination_statuses = '0'
      // } else if (
      //   Number(data?.status) === APPOINTMENT_STATUS.CODE.CLINIC_CONFIRMED
      // ) {
      //   params.multi_status = [
      //     APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
      //     APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED
      //   ].join(',')
      //   params.examination_statuses = '1'
      // } else if (
      //   Number(data?.status) === APPOINTMENT_STATUS.CODE.CLINIC_PENDING
      // ) {
      //   params.multi_status = [
      //     APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
      //     APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED
      //   ].join(',')
      //   params.examination_statuses = '0'
      // } else {
      //   params.multi_status = [
      //     APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
      //     // APPOINTMENT_STATUS.CODE.CLINIC_CONFIRMED,
      //     APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED,
      //     APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED
      //   ].join(',')
      //   params.examination_statuses = '0,1'
      // }

      if (Number(data?.doctor) > 0) {
        params.doctor_id = Number(data.doctor)
      }

      if (data.doctorId) {
        params.doctor_id = data.doctorId
      }
      if (data.doctorId !== this.$user?.doctor?.id) {
        params.clinic_id = this.$globalClinic?.id
      }

      return params
    },
    async getAppointments ({
      limit = this.limit || 10,
      page = 1,
      params = {},
      refetch = true
    }) {
      try {
        this.isLoading = true
        let paramsData = this.setParamsData({
          limit,
          page,
          data: { ...params, doctorId: params.doctor }
        })
        this.limit = limit
        this.page = page
        await this.getAppointmentRequest({ params: { ...paramsData }, refetch })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async setFiltersAppointment (params) {
      this.appointmentFilterParams = {
        ...this.appointmentFilterParams,
        ...params
      }
      this.isLoading = true
      await this.getAppointments({
        params: { ...this.appointmentFilterParams }
      })
      this.isLoading = false
    },
    onRefresh (params) {
      this.getAppointments({
        limit: this.limit,
        params: this.appointmentFilterParams
      })

      if (this.searchInput.length) {
        this.getSearchAppointments({ keyword: this.searchInput })
      }
    },

    async getSearchAppointments ({ keyword, params }) {
      const self = this

      if (!keyword?.length) {
        return
      }

      self.isLoading = true

      let paramsData = {
        search: keyword,
        limit: 10,
        sort: 'start_time',
        sort_by: 'desc',
        page: 1,
        clinic_id: self.$clinicId
      }

      paramsData = {
        ...paramsData,
        ...params
      }

      try {
        await self.getSearchAllRequest({ params: paramsData })
      } catch (error) {
        console.log(error)
      } finally {
        self.isLoading = false
      }
    },
    handleChangePagination (params) {
      this.limit = params.per_page || this.limit
      this.page = params.page || this.page

      this.getAppointments({
        page: params.page,
        limit: params.per_page,
        params: { ...this.appointmentFilterParams }
      })
    }
  },
  destroyed () {
    this.cancelAutoUpdate()
  }
}
</script>

<style lang="css" scoped>
.hodo-table /deep/ .table th,
.hodo-table /deep/ .table td {
  cursor: pointer;
}

.hodo-table /deep/ .table th {
  color: black !important;
  background-color: #e4e7ec !important;
  font-size: 14px !important;
}

.hodo-table /deep/ .table-hover tbody tr:hover {
  background-color: #ffffff;
}
</style>
