var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('custom-filters',{attrs:{"doctorId":_vm.doctorId,"search-box-class":"w-1/3","isRefresh":_vm.refreshFilters,"customStatusData":_vm.customStatusData},on:{"setFilters":_vm.handleFilters}})],1),_c('div',[_c('div',{staticClass:"mb-2 hodo-table"},[_c('b-table',{attrs:{"id":"schedule-table","busy":_vm.loading,"hover":"","responsive":"","sort-icon-right":"","show-empty":"","empty-text":_vm.$t('Không tìm thấy dữ liệu'),"head-variant":"light","fields":_vm.fields,"items":_vm.listData,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"per-page":Number(_vm.meta.per_page),"current-page":1},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2 flex flex-col items-center py-2"},[_c('b-spinner',{staticClass:"align-middle mb-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(start_time)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.appUtils.formatDateTime(data.item.start_time || new Date()))+" ")]}},{key:"cell(invoice.status)",fn:function(data){return [(
              data.item.invoice &&
              Number(data.item.invoice.status) ===
                _vm.INVOICE_STATUS.CODE.DOCTOR_ACCEPT
            )?_c('div',{staticClass:"flex justify-center"},[_c('span',{staticClass:"text-green-700"},[_vm._v(_vm._s(_vm.$t("Đã thanh toán")))])]):(
              data.item.invoice &&
              Number(data.item.invoice.status) ===
                _vm.INVOICE_STATUS.CODE.PATIENT_PAID
            )?_c('div',{staticClass:"flex justify-center"},[_c('span',{staticClass:"text-yellow-600"},[_vm._v(_vm._s(_vm.$t("Chờ xác nhận")))])]):_c('div',{staticClass:"flex justify-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-button',{attrs:{"type":"danger","size":"mini"}},[_vm._v(" "+_vm._s(_vm.$t("Chưa thanh toán"))+" ")])],1)]}}])})],1),_c('div',{staticClass:"flex justify-end items-center w-full"},[_c('el-pagination',{staticClass:"el-pagination-mdt",attrs:{"background":"","layout":"prev, pager, next","pager-count":5,"page-size":Number(_vm.meta.per_page),"total":_vm.meta.total,"hide-on-single-page":""},on:{"next-click":_vm.handleChangePage,"prev-click":_vm.handleChangePage,"current-change":_vm.handleChangePage}}),_c('div',{staticClass:"ml-1"},[_c('el-dropdown',[_c('span',{staticClass:"block py-1.5 px-2 rounded-lg border bg-white text-gray-900 text-sm",attrs:{"role":"button"}},[_c('span',[_vm._v(_vm._s(_vm.meta.per_page))]),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.$t("Trang")))]),_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.listShowRecord),function(item,index){return _c('el-dropdown-item',{key:index},[_c('div',{on:{"click":function($event){return _vm.handleSelectedShowRecord(item)}}},[_c('span',[_vm._v(_vm._s(item))])])])}),1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }