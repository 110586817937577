<template>
  <div>
    <div class="mb-2">
      <custom-filters search-box-class="w-1/3" :show-quick-date="false" :show-select-date="false"
        @setFilters="handleFilters" :customStatusData="customStatusData" />
    </div>
    <div>
      <div class="mb-2 hodo-table">
        <b-table id="schedule-table" hover responsive sort-icon-right show-empty
          :empty-text="$t('Không tìm thấy dữ liệu')" head-variant="light" :fields="fields" :items="listData"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :per-page="Number(meta.per_page)" :current-page="1"
          @row-clicked="rowClickHandler">
          <template #cell(start_time)="data">
            <span>{{ appUtils.formatTime(data.item.start_time || new Date()) }}</span>
            <span>:</span>
            <span>{{ appUtils.formatTime(data.item.end_time || new Date()) }}</span>
            <span>-</span>
            <span>{{ appUtils.formatDate(data.item.start_time || new Date()) }}</span>
          </template>
          <template #cell(invoice.status)="data">
            <div v-html="renderPaymentStates(data)" />
          </template>
          <template #cell(examination_status)="data">
            <div :set="status = getApptStatus(data.item.status)">
              <span :class="status.class">{{ status.text }}</span>
            </div>
          </template>
        </b-table>
      </div>
      <div class="flex justify-end items-center w-full">
        <!-- <div v-if="meta && meta.count > pagination.perPage">
          <b-pagination
            aria-controls="schedule-table"
            v-model="pagination.currentPage"
            :total-rows="totalPages"
            :per-page="pagination.perPage"
            size="sm"
            align="right"
            first-number
            last-number
            class="hodo-schedule-table m-0"
            prev-class="prev-item border border-gray-200 rounded mx-1"
            next-class="next-item border border-gray-200 rounded mx-1"
            :page-class="'border border-gray-200 rounded mx-1'"
            :ellipsis-class="'border border-gray-200 rounded mx-1'"
          >
            <template #page="{ page, active }">
              <span :class="active ? 'text-white' : 'text-gray-600'">{{ page }}</span>
            </template>
            <template #prev-text>
              <i class="el-icon-arrow-left text-gray-600" />
            </template>
            <template #next-text>
              <i class="el-icon-arrow-right text-gray-600" />
            </template>
          </b-pagination>
        </div> -->
        <el-pagination background layout="prev, pager, next" :pager-count="5" :page-size="Number(meta.page_size)"
          :total="meta.total" class="el-pagination-mdt" @next-click="handleChangePage" @prev-click="handleChangePage"
          @current-change="handleChangePage" hide-on-single-page></el-pagination>
        <div class="ml-1">
          <el-dropdown>
            <span class="block py-1.5 px-2 rounded-lg border bg-white text-gray-900 text-sm" role="button">
              <span>{{ meta.page_size }}</span>
              <span class="mx-1">/</span>
              <span>{{ $t('Trang') }}</span>
              <i class="el-icon-caret-bottom" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in listShowRecord" :key="index">
                <div @click="handleSelectedShowRecord(item)">
                  <span>{{ item }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.hodo-table /deep/ .table th,
.hodo-table /deep/ .table td {
  cursor: pointer;
}

.hodo-table /deep/ .table th {
  color: black !important;
  background-color: #e4e7ec !important;
  font-size: 14px !important;
}

.hodo-table /deep/ .table-hover tbody tr:hover {
  background-color: #ffffff;
}

.el-pagination-mdt /deep/ {
  padding: 0;
}

.el-pagination-mdt /deep/ .number.active {
  background-color: #20419b !important;
}

.el-pagination-mdt /deep/ .number.active:hover {
  color: white !important
}

.el-pagination-mdt /deep/ .number:hover {
  color: #20419b !important;
}
</style>

<script>
import { BTable, BPagination } from 'bootstrap-vue'
import DropdownButton from '../../../components/DropdownButton'
import CustomFilters from '../../../components/CustomFilters'
import { debounce } from 'lodash'
import {
  INVOICE_STATUS,
  PATIENT_RECEPTION_TYPE,
  EXAMINATION_STATUS,
  APPOINTMENT_STATUS
} from '../../../utils/constants'
import { mapActions } from 'vuex'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'WaitingExaminationModal',
  components: {
    BTable,
    BPagination,
    DropdownButton,
    CustomFilters
  },
  props: {
    items: {
      type: Array
    },
    metaData: {
      type: Object
    },
    refreshFilters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      EXAMINATION_STATUS,
      listShowRecord: [10, 20, 50, 100],
      pagination: {
        perPage: 10,
        currentPage: 1
      },
      today: this.moment(),
      sortBy: 'start_time',
      sortDesc: false,
      fields: [
        {
          key: 'person.name',
          label: this.$t('Họ và tên'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        },
        // { key: 'person.contact_phone', label: this.$t('SĐT'), thClass: 'text-nowrap text-sm', tdClass: 'text-sm', sortable: true },
        // { key: 'appt_reason', label: this.$t('Lý do khám'), thClass: 'text-nowrap text-sm', tdClass: 'text-sm', sortable: true },
        {
          key: 'consulting_room.name',
          label: this.$t('Phòng tư vấn'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        },
        {
          key: 'doctor.name',
          label: this.$t('Bác sĩ'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        },
        {
          key: 'start_time',
          label: this.$t('Thời gian'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        },
        {
          key: 'invoice.status',
          label: this.$t('Thanh toán'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        },
        {
          key: 'examination_status',
          label: this.$t('Trạng thái'),
          thClass: 'text-nowrap text-sm',
          tdClass: 'text-sm',
          sortable: true
        }
      ],
      listData: this.items,
      meta: this.metaData || { page_size: 10, total: 1 },
      totalPages: this.metaData?.total || 0,
      appUtils,
      customStatusData: {
        options: [
          {
            value: 'All',
            label: this.$t('Tất cả')
          },
          {
            value: APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
            label: this.$t('Chờ khám')
          },
          {
            value: APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED,
            label: this.$t('Đã hủy lượt khám')
          }
        ],
        value: APPOINTMENT_STATUS.CODE.CLINIC_PENDING
      }
    }
  },
  watch: {
    items (data) {
      console.log({ items: data })

      this.loading = true
      if (data) {
        this.listData = data
        this.loading = false
      }
    },
    metaData (data) {
      console.log({ metaData: data })
      if (data) {
        this.meta = data
        this.totalPages = data.total
      }
    }
  },
  created () { },
  mounted () { },
  computed: {},
  methods: {
    ...mapActions('appointmentStore', ['updateAppointmentRequest']),
    getApptStatus (stt) {
      switch (stt) {
        case EXAMINATION_STATUS.CODE.WAITING:
          return { class: 'text-yellow-600', text: 'Chờ khám' }
        case EXAMINATION_STATUS.CODE.BEING:
          return { class: 'text-blue-800', text: 'Đang khám' }
        case EXAMINATION_STATUS.CODE.DONE:
          return { class: 'text-green-700', text: 'Đã khám xong' }
        case EXAMINATION_STATUS.CODE.CANCELED:
          return { class: 'text-red-700', text: 'Đã hủy lượt' }
        default:
          return { class: 'text-yellow-600', text: 'Chờ khám' }
      }
    },
    renderPaymentStates (data) {
      switch (Number(data.item?.appt?.invoice?.status)) {
        case INVOICE_STATUS.CODE.PATIENT_PENDING:
          return `<span class="text-red-600">${this.$t(
            'Chưa thanh toán'
          )}</span>`
        case INVOICE_STATUS.CODE.PATIENT_PAID:
          return `<span class="text-yellow-600">${this.$t(
            'Chờ xác nhận'
          )}</span>`
        case INVOICE_STATUS.CODE.DOCTOR_ACCEPT:
          return `<span class="text-green-700">${this.$t(
            'Đã thanh toán'
          )}</span>`
        default:
          return `<span class="text-red-600">${this.$t(
            'Chưa thanh toán'
          )}</span>`
      }
    },
    rowClickHandler (record, index) {
      if (record) {
        this.$router.push({
          name: 'AppointmentDetailV2',
          params: { id: record.id },
          query: { type: PATIENT_RECEPTION_TYPE.CODE.WAITING_EXAMINATION }
        })
      }
    },
    handleSelectedShowRecord (value) {
      // this.pagination.perPage = value
      this.$emit('onChangePagination', { per_page: value })
    },
    handleFilters (params) {
      this.$emit('setFilters', params)
    },
    handleChangePage: debounce(function (newPage) {
      this.$emit('onChangePagination', { page: newPage })
    }, 500)
  }
}
</script>
