<template>
  <div class="relative flex flex-col">
    <div class="ml-auto mb-3">
      <button @click="handleAddNewPatient" type="button"
        class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 hover:bg-blue-800 text-white transition-all duration-200 rounded-lg h-9 px-3 py-2">
        <i class="el-icon-plus text-lg mr-2" />
        <span>{{ $t('Thêm mới bệnh nhân') }}</span>
      </button>
    </div>
    <div class="hodo-el relative z-20 mb-6 bg-gray-100 rounded-2xl">
      <form @submit.prevent="enterSearch">
        <div class="hodo-el-input-form">
          <span @click="enterSearch" role="button" class="absolute z-10 top-1/2 left-2 lg:left-8 -translate-y-1/2 -mt-4">
            <i class="el-icon-search text-lg lg:text-2xl" />
          </span>

          <el-input @keyup.enter="enterSearch" @input="debounceSearch" name="fullSearch"
            :placeholder="$t('Nhập tên, SĐT, CCCD, hoặc QR Code')" v-model="searchInput" clearable
            class="form-full-search text-lg lg:text-2xl text-center w-full rounded-2xl overflow-hidden border border-blue-200 bg-gray-100" />
        </div>
      </form>

      <!-- <div class="absolute top-0 right-0 bottom-0 overflow-hidden">
        <el-tooltip :content="$t('Thêm bệnh nhân')" placement="top">
          <button @click="handleAddNewPatient"
            class="rounded-r-2xl h-full px-4 border border-blue-200 outline-none focus:outline-none shadow-none bg-gray-100 hover:bg-blue-700 hover:text-white transition-all duration-200">
            <i class="el-icon-plus text-lg lg:text-2xl" />
            <span class="sr-only">{{ $t('Thêm bệnh nhân') }}</span>
          </button>
        </el-tooltip>
      </div> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .hodo-el {
    &-input-form {
      // padding-right: 90px !important;
    }

    .form-full-search .el-input__inner {
      font-size: inherit !important;
      background-color: transparent !important;
      text-align: inherit !important;
      padding: 20px 24px 20px 64px !important;
      height: auto !important;
      transition: all 0.2s ease !important;
      border-radius: 1rem 0 0 1rem !important;

      &:focus {
        background-color: white !important;
      }
    }

    .el-input .el-input__clear {
      font-size: 24px !important;
      width: 48px !important;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import appUtils from '../../../utils/appUtils'
import { APPOINTMENT_STATUS } from '../../../utils/constants'

export default {
  name: 'SearchAppointmentData',
  data () {
    return {
      isLoading: false,
      debounce: null,
      typing: false,
      searchInput: '',
      listData: [],
      limit: 10,
      appUtils,
      APPOINTMENT_STATUS
    }
  },
  watch: {
    searchData (data) {
      if (data) {
        this.listData = data
      }
    }
  },
  computed: {
    ...mapGetters({
      searchData: 'appointmentStore/searchAllData'
    })
  },
  methods: {
    ...mapActions('appointmentStore', ['getSearchAllRequest']),
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.getSearchAppointments(this.searchInput)
        this.$emit('setSearchInput', this.searchInput)
      }, 600)
    },
    enterSearch () {
      if (!this.searchInput.length) return

      this.getSearchAppointments(this.searchInput)
      this.$emit('setSearchInput', this.searchInput)
    },
    handleAddNewPatient () {
      this.$emit('addNewPatient')
    },
    async getSearchAppointments (search) {
      if (!search?.length) {
        this.listData = []
        return
      }

      this.isLoading = true
      this.$emit('setIsLoading', true)

      let params = {
        search,
        limit: this.limit,
        sort: 'start_time',
        sort_by: 'desc',
        page: 1,
        clinic_id: this.$clinicId
      }

      try {
        await this.getSearchAllRequest({ params })
      } catch (error) {
        console.log(error)
        this.isLoading = false
        this.$emit('setIsLoading', false)
      } finally {
        this.isLoading = false
        this.$emit('setIsLoading', false)
      }
    },
    rowClickHandler (record, index) {
      if (record) {
        console.log(record)
        this.$router.push({
          name: 'AppointmentDetailV2',
          params: { id: record.id }
        })
      }
    }
  }
}
</script>
