<template>
  <div class="container">
    <HeaderWindow
      :title="$t('obj_health_records.lbl_make_an_appointment')"
      @onClose="onClose"
    ></HeaderWindow>

    <div class="bg-white lg:p-4 rounded-xl">
      <AppointmentForm
        :isHasDisease="isHasDisease"
        :disease="disease"
        v-show="step == 'input'"
        @nextStep="nextStep"
      >
      </AppointmentForm>
      <AppointmentConfirm
        v-if="step == 'confirm'"
        :data="data_appt"
        @backStep="step = 'input'"
        @refresh="onClose"
      >
      </AppointmentConfirm>
    </div>
  </div>
</template>
<script>
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import AppointmentForm from '../../components/Appointment/AppointmentForm'
import AppointmentConfirm from '../../components/Appointment/AppointmentConfirm'
export default {
  name: 'CreateAppointment',
  components: { AppointmentForm, HeaderWindow, AppointmentConfirm },
  data () {
    return {
      disease: null,
      loading: false,
      step: 'input',
      data_appt: null,
      isHasDisease: !!this.$route.query?.disease_id
    }
  },
  mounted () {
    let self = this
    if (this.isHasDisease) { self.getPersonsDiseasesDetail(self.$route.query.disease_id) }
  },
  methods: {
    onClose () {
      return this.$router.go(-1)
    },
    async getPersonsDiseasesDetail (id) {
      let self = this
      self.loading = true
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(id)
          .then((res) => {
            if (res && res.data) {
              self.disease = res.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async createAppt (data) {},
    nextStep (data) {
      if (!data) return
      this.data_appt = data
      this.step = 'confirm'
    }
  }
}
</script>
<style lang="css" scoped></style>
