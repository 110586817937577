<template>
  <div
    class="m-3 p-3 rounded-lg bg-white text-black"
    v-if="appointment"
  >
    <div class="page-top d-flex">
      <div class="header-title text-center">
        <p class="mb-0 robo-24-500 txt-pri">{{$t('schedule_page.lbl_appt_detail')}}</p>
      </div>
      <div class="flex space-x-4">
        <div
          class="text-xl font-medium whitespace-nowrap"
          :class="[0, 1].includes(appointment.examination_status) ? 'text-yellow-800' : appointment.examination_status === 2 ? 'text-blue-700' : appointment.examination_status === 3 ? 'text-yellow-600' : appointment.examination_status === 4 ? 'text-yellow-700' : appointment.examination_status === 5? 'text-green-700' : 'text-red-700'"
        >{{ [0, 1].includes(appointment.examination_status)
                          ? $t('schedule_page.lbl_sts_wait')
                          : appointment.examination_status === 2
                          ? $t('schedule_page.lbl_sts_examining')
                          : appointment.examination_status === 3
                          ? $t('schedule_page.lbl_sts_wait_result') 
                          : $t('schedule_page.lbl_sts_finished') }}</div>
        <div class="header-icon w-8 h-8">
          <img
            class="cursor-pointer"
            src="../../../public/assets/images/icon/x-gray.svg"
            alt=""
            width="32"
            height="32"
            @click="goBack()"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="mt-3 fs-14 fw-400 row">
        <div class="col-lg-4 col-md-6 pt-2 pr-3">
          <div class="d-flex py-1 mb-3">
            <div
              class="mt-2"
              style="min-width: 40px"
            >
              <span
                v-if="hasAvatar(appointment)"
                class="avatar avatar-sm avatar-rounded"
                :style="
                      'background-image: url(' +
                      getImageURL(appointment.person.user.avatar) +
                      ')'
                    "
              ></span>
              <span
                v-if="!hasAvatar(appointment)"
                class="
                      avatar avatar-sm avatar-rounded
                      bg-secondary
                      text-white
                    "
              >
                {{
                      appointment.person && appointment.person.name
                        ? appointment.person.name.charAt(0)
                        : ""
                    }}
              </span>
            </div>
            <div class="flex-fill">
              <p class="p-0 m-0 fs-20 fw-500 txt-pri">
                {{ appointment.person ? appointment.person.name : ""
                    }}
              </p>
              <div>{{ appointment.person && getPersonInfoString(appointment.person) }}</div>
              <div>{{
                    appointment.person ? appointment.person.phone : ""
                  }}</div>
              <div>{{ appointment.person && appointment.person.address }}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 pt-2 pr-3">
          <div
            class="d-flex align-items-center"
            :class="
                    appointment.clinic || appointment.consulting_room
                      ? 'mb-0'
                      : 'mb-3'
                  "
          >
            <div class="w-40">{{$t('schedule_page.lbl_method')}}: </div>
            <p class="mb-0 fw-500">
              {{
                      appointment.method == 1
                        ? $t('schedule_page.lbl_at_workspace')
                        : $t('schedule_page.lbl_video')
                    }}
            </p>
          </div>
          <div
            class="d-flex align-items-center"
            v-if="appointment.doctor"
          >
            <div class="w-40">{{$t('schedule_page.lbl_doctor')}}: </div>
            <div>
              <p class="mb-0 fw-500">
                {{ appointment.doctor ? appointment.doctor.name : "" }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="w-40">{{$t('schedule_page.lbl_time')}}: </div>
            <div>
              <p class="mb-0 fw-500">
                {{ getTime(appointment.start_time) }} -
                {{ formatDateDMY(appointment.start_time) }}
              </p>
            </div>
            <!-- <div v-if="appointment.method === 2">
              <p class="mb-0 fw-500">
                {{ duration(appointment.start_time, appointment.end_time) }}
                phút
              </p>
            </div> -->
          </div>
          <div class="d-flex align-items-center mb-2">
            <div class="w-40">{{$t('schedule_page.lbl_reason')}}: </div>
            <p class="mb-0 fw-500">
              {{ appointment.note || this.$t('schedule_page.lbl_dont_have') }}
            </p>
          </div>
          <div
            v-if="appointment.method === 2 && !!appointment.invoice"
            :set="(status = getInvoiceStatus(appointment))"
          >
            <p class="mb-0 d-flex align-items-center">
              <span class="w-40 fs-14 fw-500 text-black">{{$t('schedule_page.lbl_invoice_code')}}</span>
              <span class="robo-20-500 txt-pri">{{
                    appointment.invoice.code
                  }}</span>
            </p>
            <p
              class="mb-1 fs-14 fw-500 mt-1"
              :class="status.class"
            >
              {{ status.text }}
            </p>
          </div>
        </div>
        <div class="col-lg-8 pt-2">
          <div :set="(disease = appointment.person_diseases)">
            <div
              class="mb-3"
              v-if="disease && inCareTeam()"
            >
              <div class="d-flex justify-content-between align-items-center">
                <p class="mb-1 fs-16 fw-500 txt-black">{{$t('schedule_page.lbl_care_plan')}}</p>
                <a
                  @click="goToDisease()"
                  href="javascript:;"
                  class="robo-16-400 txt-pri tdc-none"
                >{{$t('schedule_page.lbl_view_detail')}}
                  <img
                    src="../../../public/assets/images/icon/arrow-right-blue.svg"
                    alt=""
                    width="24"
                  /></a>
              </div>
              <p class="mb-1 robo-16-400">{{ disease.diseases_name }}</p>
            </div>
            <div
              class="mb-3"
              v-if="
                    disease &&
                    inCareTeam() &&
                    (!!disease.contact_name ||
                      !!disease.contact_relationship ||
                      !!disease.contact_phone)
                  "
            >
              <p class="mb-1 fs-16 fw-500 txt-black">{{$t('schedule_page.lbl_contact')}}</p>
              <p class="mb-1 robo-16-400">
                {{ disease.contact_name
                    }}{{
                      disease.contact_relationship
                        ? ", " + disease.contact_relationship
                        : ""
                    }}{{
                      disease.contact_phone ? ", " + disease.contact_phone : ""
                    }}
              </p>
            </div>
          </div>
          <div class="mx-3">
            <div class="flex justify-between items-center border border-solid rounded p-3 mb-3">
              <div>
                <div>{{$t('schedule_page.lbl_msg_addition_online')}}</div> 
              </div>
              <div class="text-xl font-semibold cursor-pointer"><span class="mr-3"><svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.1458 8.88854C24.9685 8.78615 24.7673 8.73224 24.5625 8.73224C24.3577 8.73224 24.1565 8.78615 23.9792 8.88854L19.8608 10.9185C19.8276 10.0127 19.4442 9.15509 18.7914 8.52622C18.1386 7.89735 17.2673 7.54627 16.3608 7.54688H5.86084C4.93258 7.54688 4.04234 7.91562 3.38597 8.572C2.72959 9.22838 2.36084 10.1186 2.36084 11.0469V18.0469C2.36084 18.9751 2.72959 19.8654 3.38597 20.5217C4.04234 21.1781 4.93258 21.5469 5.86084 21.5469H16.3608C17.2673 21.5475 18.1386 21.1964 18.7914 20.5675C19.4442 19.9387 19.8276 19.081 19.8608 18.1752L24.0142 20.2519C24.1732 20.3334 24.3488 20.3773 24.5275 20.3802C24.7459 20.3809 24.9602 20.3203 25.1458 20.2052C25.314 20.1001 25.4525 19.9539 25.5485 19.7804C25.6444 19.6069 25.6946 19.4118 25.6942 19.2135V9.88021C25.6946 9.68194 25.6444 9.48684 25.5485 9.31333C25.4525 9.13982 25.314 8.99362 25.1458 8.88854ZM17.5275 18.0469C17.5275 18.3563 17.4046 18.653 17.1858 18.8718C16.967 19.0906 16.6703 19.2135 16.3608 19.2135H5.86084C5.55142 19.2135 5.25467 19.0906 5.03588 18.8718C4.81709 18.653 4.69417 18.3563 4.69417 18.0469V11.0469C4.69417 10.7375 4.81709 10.4407 5.03588 10.2219C5.25467 10.0031 5.55142 9.88021 5.86084 9.88021H16.3608C16.6703 9.88021 16.967 10.0031 17.1858 10.2219C17.4046 10.4407 17.5275 10.7375 17.5275 11.0469V18.0469ZM23.3608 17.3235L19.8608 15.5735V13.5202L23.3608 11.7702V17.3235Z"
                      fill="#03267A"
                    />
                  </svg>
                </span>{{$t('schedule_page.lbl_create_onlineroom')}}</div>
            </div>
            <div
              class="border border-solid rounded p-3 my-3 cursor-pointer hover:bg-gray-100"
              @click="openCreateMb(appointment)"
            >
              <div class="fs-28 fw-500 text-center txt-pri">{{$t('schedule_page.lbl_exam_pape')}}</div>
              <div class="flex justify-end">
                <span class="bg-blue-300 text-base font-semibold px-6 py-2 rounded-full">{{$t('schedule_page.lbl_status_editing')}}</span>
              </div>
            </div>
            <ConsultationTemp
              :appointment="appointment"
              :consultationProp="appointment.consultation"
              @refresh="getAppts"
            />
          </div>
        </div>
        <div class="col-lg-4 pt-2 pl-lg-4 border-left">
          <div>
            <button
              v-if="
                    appointment.method == 2 &&
                    appointment.room_call &&
                    appointment.room_call.room_name
                  "
              :class="!permitCall(appointment) ? 'disabled' : ''"
              @click="videoCall(appointment.room_call.room_name)"
              :disabled="!checkMyAppt || !isPaid(appointment)"
              class="btn-l w-100 btn-c text-white mb-3"
            >
              <img
                src="../../../public/assets/images/icon/white-video-not-fill.svg"
                alt=""
                width="24"
                class="mr-2"
              />
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_join_videocall')}}</span>
            </button>
            <button
              :disabled="appointment.examination_status === 6 || !checkMyAppt"
              @click="ApptStatus(2)"
              class="btn-l w-100 btn-c text-white mb-3"
              v-if="appointment.examination_status === 1 || appointment.examination_status === 0 || appointment.examination_status === 6"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_start')}}</span>
            </button>
            <button
              v-if="appointment.examination_status === 2"
              @click="ApptStatus(5)"
              class="btn-l w-100 btn-c text-white mb-3"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_finished')}}</span>
            </button>
            <button
              :disabled="!appointment.treatment_type"
              v-if="appointment.examination_status === 1 || appointment.examination_status === 0 || appointment.examination_status === 6 || appointment.examination_status === 2"
              @click="sendConsultationToPatient()"
              class="btn-l w-100 btn-c text-white mb-3"
            >
              <!-- <img
                src="../../../public/assets/images/icon/invoice-white.svg"
                alt=""
                width="24"
                class="mr-2"
              /> -->
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_results')}}</span>
            </button>
            <!-- <div
              v-if="appointment.consultation && appointment.consultation.sms_latest"
              class="text-xs space-x-2 text-center"
            >
              <span class="txt-pri">Đã gửi</span>
              <span>{{ appointment.consultation && appointment.consultation.sms_latest.user && appointment.consultation.sms_latest.user.name }}</span>
              <span>{{ getDateTime4(appointment.consultation.sms_latest.created_at) }}</span>
            </div> -->
            <button
              :disabled="appointment.examination_status === 2 || !checkMyAppt"
              v-if="
                 appointment.examination_status === 1 || appointment.examination_status === 0 || appointment.examination_status === 6 || appointment.examination_status === 2
                "
              @click="
                  ApptStatus(6)
                "
              class="btn-l w-100 btn-c text-white mb-3 text-center"
            >
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_cancel_visit')}}</span>
            </button>
            <button
              v-if="appointment.examination_status === 1 || appointment.examination_status === 0 || appointment.examination_status === 6 || appointment.examination_status === 2"
              @click="goToInvoice()"
              class="btn-l w-100 btn-c text-white mb-3"
            >
              <!-- <img
                src="../../../public/assets/images/icon/invoice-white.svg"
                alt=""
                width="24"
                class="mr-2"
              /> -->
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_show_bill')}}</span>
            </button>
          </div>
          <!-- <button
            class="btn-l w-100 btn-c mb-3"
            v-if="appointment.method === 2"
            @click="goToConsu(appointment)"
            :disabled="!checkMyAppt"
          >
            <img
              src="../../../public/assets/images/icon/edit-3.svg"
              alt=""
              width="24"
              class="mr-2"
            />
            <span class="fs-16 fw-500">Phiếu tư vấn</span>
          </button> -->
          <!-- <button
            v-if="
                  checkMyAppt &&
                  (!appointment.invoice || appointment.invoice.status !== 3)
                "
            @click="
                  onCancelAppt(
                    appointment.id,
                    appointment.start_time,
                    appointment.invoice
                  )
                "
            :disabled="!checkMyAppt"
            class="btn-l w-100 btn-c text-white mb-3"
          >
            <img
              src="../../../public/assets/images/icon/delete-blue.svg"
              alt=""
              width="24"
              class="mr-2"
            />
            <span class="fs-16 fw-500">Huỷ lượt</span>
          </button> -->
          <div v-if="appointment.examination_status === 5">
            <p class="fs-16 fw-500 text-center">{{$t('schedule_page.lbl_next_action')}}</p>
            <button
              v-if="appointment.treatment_type === 1 || !appointment.treatment_type"
              class="btn-l w-100 btn-d text-white mb-3"
              @click="ApptTreatment(1)"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_let_gohome')}}</span>
            </button>
            <button
              v-if="appointment.treatment_type === 2 || !appointment.treatment_type"
              class="btn-l w-100 btn-d text-white mb-3"
              @click="ApptTreatment(2)"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_wait_result_read')}}</span>
            </button>
            <button
              v-if="appointment.treatment_type === 3 || !appointment.treatment_type"
              class="btn-l w-100 btn-d text-white mb-3"
              @click="ApptTreatment(3)"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_add_next_appt')}}</span>
            </button>
            <button
              v-if="appointment.treatment_type === 4 || !appointment.treatment_type"
              class="btn-l w-100 btn-d text-white mb-3"
              @click="ApptTreatment(4)"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_other_specialist')}}</span>
            </button>
            <button
              v-if="appointment.treatment_type === 5 || !appointment.treatment_type"
              class="btn-l w-100 btn-d text-white mb-3"
              @click="ApptTreatment(5)"
            >
              <span class="fs-16 fw-500 text-white">{{$t('schedule_page.lbl_hospitalize')}}</span>
            </button>
            <button
              v-if="appointment.treatment_type"
              @click="sendConsultationToPatient()"
              class="btn-l w-100 btn-c text-white"
              :class="consultationSms && consultationSms.sms_latest ? 'mb-0' : 'mb-3'"
            >
              <!-- <img
                src="../../../public/assets/images/icon/invoice-white.svg"
                alt=""
                width="24"
                class="mr-2"
              /> -->
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_results')}}</span>
            </button>
            <div
              v-if="consultationSms && consultationSms.sms_latest"
              class="text-xs space-x-2 text-center mb-2"
            >
              <span class="txt-pri">{{$t('schedule_page.lbl_sent')}}</span>
              <span>{{ consultationSms && consultationSms.sms_latest.user && consultationSms.sms_latest.user.name }}</span>
              <span>{{ getDateTime4(consultationSms.sms_latest.created_at) }}</span>
            </div>
            <button
              v-if="appointment.treatment_type"
              @click="goToInvoice()"
              class="btn-l w-100 btn-c text-white mb-3"
            >
              <!-- <img
                src="../../../public/assets/images/icon/invoice-white.svg"
                alt=""
                width="24"
                class="mr-2"
              /> -->
              <span class="fs-16 fw-500">{{$t('schedule_page.lbl_show_bill')}}</span>
            </button>
          </div>
        </div>
      </div>
      <el-dialog
        :title="$t('schedule_page.lbl_notify_header')"
        :visible.sync="DialogVisibleStatus"
        width="30%"
        append-to-body
        center
      >
        <div class="fs-16 fw-500 word-break">
          <div>{{$t('schedule_page.lbl_msg_start_confirm')}}</div>
          <div class="mt-4 flex justify-end">
            <el-button
              type="primary"
              @click="openMsgStatus"
            >Ok</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <ModalSelectHR
      v-if="appointment"
      ref="ModalSelectHR"
      :person="appointment.person"
      @onContinue="onOpenCreateMb"
    />
    <ModalAddConsultation
      v-if="selectedHr && appointment"
      :hr="selectedHr"
      :user="appointment.person"
      :appt_id="appointment.id"
      ref="ModalAddConsultation"
    />
    <PhieuKhamBenhDetail
      ref="PhieuKhamBenhDetail"
      :appt="appointment"
      @refresh="getAppts"
    />
    <ModalViewConsultation ref="ModalViewConsultation" />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import statusAppt from '@/constants/statusAppt'

import ModalSelectHR from '../../components/Appointment/ModalSelectHR.vue'
import ModalAddConsultation from '../HealthRecords/ModalAddConsultation.vue'
import PhieuKhamBenhDetail from '../HealthRecords/PhieuKhamBenh/ModalAdd.vue'
import ModalViewConsultation from '../HealthRecords/PhieuKhamBenh/ViewPhieuKham.vue'
import ConsultationTemp from './ConsultationTemp.vue'
export default {
  name: 'AppointmentDetail',
  components: { ModalSelectHR, ModalAddConsultation, PhieuKhamBenhDetail, ConsultationTemp, ModalViewConsultation },
  computed: {
    checkMyAppt () {
      let appointment = this.appointment
      if (appointment) {
        return appointment.doctor_id === this.doctor_id
      }
    }
  },
  watch: {
    appointment (value) {
      if (value) {
        value.consultation && this.getConsultationByIDV2(value.consultation?.id)
      }
    },
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getAppts()
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      DialogVisibleStatus: false,
      consultationSms: null,
      root_type: 1,
      appointment: null,
      clinic_id: null,
      doctor_id: null,
      request_id: null,
      request: null,
      loading: false,
      person: null,
      person_id: null,
      appt_edit: null,
      selectedHr: null,
      statusAppt,
      valueStatus: ''
    }
  },
  mounted () {
    let self = this
    if (self.$route?.params?.id) {
      self.getAppts()
    } else {
      self.$router.push({ path: '/doctor/appointment-management' })
      return false
    }
    let user = this.$user
    self.clinic_id = this.$clinicId
    self.doctor_id = user.doctor.id
    window.$(document).ready(function () {
      window.$('#modal__appt').on('hidden.bs.modal', function (e) {
        self.appt_edit = null
      })
      window.$('#modal__content').on('hidden.bs.modal', function (e) {
        self.content = ''
      })
    })
  },
  destroyed () {
    this.$bus.off('change:status')
  },
  methods: {
    async getConsultationByIDV2 (id) {
      try {
        const self = this
        await this.$rf
          .getRequest('DoctorRequest')
          .getConsultationByIDV2(id)
          .then((res) => {
            self.consultationSms = res.data
          })
      } catch (error) {
        console.log('', error)
      }
    },
    async ApptTreatment (value) {
      const params = {
        treatment_type: value
      }
      await this.$rf.getRequest('DoctorRequest').ApptTreatment(this.appointment.id, params)
      this.getAppts()
    },
    async ApptStatus (value) {
      const params = {
        status: value
      }
      await this.$rf.getRequest('DoctorRequest').ApptStatus(this.appointment.id, params)
      this.getAppts()
    },
    async sendConsultationToPatient () {
      const self = this
      if (!self.consultationSms) return
      if (self.consultationSms.sms_latest) {
        if (!confirm(`Kết quả khám đã được trả cho Bệnh nhân vào ${self.getDateTime4(self.consultationSms.sms_latest.created_at)}, bạn có chắc muốn gửi lại cho Bệnh nhân?`)) return
      }
      self.save_loading = true
      await self.$rf.getRequest('DoctorRequest').sendConsultationToPatient(this.consultationSms.id).then((resp) => {
        self.$emit('refresh')
        this.getAppts()
        self.$message({
          message: 'Đã gửi thành công',
          type: 'success'
        })
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        self.save_loading = false
      })
    },
    // showInvoiceStatus () {
    //   let appointment = this.appointment
    //   return appointment && appointment.invoice && appointment.method === 2 && (appointment.status === 2 || appointment.status === 5)
    // },
    showInvoiceStatus () {
      let appointment = this.appointment
      return appointment && appointment.invoice
    },
    hasAvatar (person) {
      return !!(person && person.user && person.user.avatar)
    },
    inCareTeam () {
      let appointment = this.appointment
      if (appointment?.person_diseases?.care_team?.care_team_doctor) {
        let team = appointment?.person_diseases?.care_team?.care_team_doctor
        let doctor = this.$user?.doctor
        return team.find(mem => doctor.id === mem.doctor_id)
      }
    },
    getDateTime4 (date_time) {
      return date_time && window.moment(date_time).format('HH:mm:ss DD/MM/YYYY')
    },
    showContent (content) {
      if (content) {
        this.content = content
        window.$('#modal__content').modal('show')
      }
    },
    getBreadcrumbData () {
      let data = {
        icon: 'appointment.svg',
        pages: [
          { text: this.$t('schedule_page.lbl_calendar'), active: false, path: '/doctor/appointment-management' },
          { text: this.$t('schedule_page.lbl_'), active: true, path: '' }
        ]
      }
      return data
    },
    getContent (content, limit) {
      if (content) {
        if (limit && content.length > limit) {
          return content.slice(0, limit)
        } else {
          return content
        }
      }
    },
    permitCall (appointment) {
      let self = this
      // if (appointment && (appointment.status === 2 || appointment.status === 5) && !self.isExpire(appointment.start_time)) {
      if (appointment && (appointment.status === 2 || appointment.status === 5)) {
        if (self.isPaid(appointment)) {
          return true
        } else {
          return false
        }
      }
    },
    isPaid (appointment) {
      if (appointment) {
        if (appointment.invoice) {
          return appointment.invoice.status === 3
        } else {
          return false
        }
      }
    },
    onRefresh () {
      let self = this
      self.onShowModalAppt(false)
      self.getAppts()
    },
    goToRequestDetail () {
      if (this.request_id) {
        this.$router.push('/doctor/request-detail/' + this.request_id + '/info')
      }
    },
    goBack () {
      if (this.$route.query.name) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'AppointmentManagement' })
      }
    },
    goToCall () {
      if (this.appointment && this.appointment.room_call) {
        this.$router.push('/doctor/' + this.appointment.id + '/meeting-room')
      }
    },
    goToInvoice () {
      if (this.appointment && this.appointment.invoice) {
        let invoice = this.appointment.invoice
        this.$router.push({ path: '/doctor/invoice/' + invoice.id + '?token=' + invoice.token })
      }
    },
    goToDisease () {
      if (this.appointment && this.appointment.person_diseases_id) {
        this.$router.push(`/doctor/diseases/${this.appointment.person_diseases_id}`)
      }
    },
    goToPhieuTuVan () {
      this.$router.push('/doctor/phieutuvan/' + this.appointment.id)
    },
    async getAppts () {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getApptsV2(self.$route?.params?.id).then(resp => {
          if (resp && resp.data) {
            self.appointment = resp.data
            self.person = resp.data.person ? resp.data.person : null
            self.request_id = resp.data.request ? resp.data.request.id : null
            self.person_id = resp.data.person ? resp.data.person.id : ''
            self.request = resp.data.request ? resp.data.request : null
            const personHR = resp.data.person
            this.$store.commit('setPersonHR', { personHR })
          } else {
            self.goBack()
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally { }
    },
    async onDeleteAppt (id) {
      let self = this
      let cf = window.confirm('Bạn có chắc chắn muốn xóa lịch hẹn này?')
      if (cf) {
        try {
          await self.$rf.getRequest('DoctorRequest').deleteAppointment(id)
          self.$router.push({ path: '/doctor/appointment-management' })
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    },
    async onCancelAppt (id, start_time, invoice) {
      let self = this
      if (invoice && invoice.status === 3) return
      let cf = window.confirm('Bạn có chắc chắn muốn huỷ lịch hẹn này?')
      if (cf) {
        try {
          var params = {
            status: 3
          }
          await self.$rf.getRequest('DoctorRequest').postAppointmentsUpdateStatus(id, params).then(res => {
            self.getAppts()
          })
          // self.$router.push({ path: '/doctor/appointment-management' })
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    },
    onEditAppt () {
      if (!this.appointment) return
      this.$router.push({ path: `/doctor/appointment-update/${this.appointment.id}` })
    },
    getApptStatus (stt) {
      let statuses = []
      statuses[1] = { class: 'text--yellow', text: 'Chờ xác nhận' }
      statuses[2] = { class: 'text--green', text: 'Xác nhận khám' }
      statuses[3] = { class: 'text--red', text: 'Bác sĩ huỷ lịch' }
      statuses[4] = { class: 'text--yellow', text: 'Chờ xác nhân' }
      statuses[5] = { class: 'text--green', text: 'Xác nhận khám' }
      statuses[6] = { class: 'text--red', text: 'Bệnh nhân từ chối' }
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    isExpire (date_time) {
      return new Date(date_time) < new Date()
    },
    formatTimeFromNow (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow()
      } else {
        // return window.moment(date_time).format('HH:mm DD/MM/YYYY')
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    },
    onShowModalAppt (show) {
      window.$('#modal__appt').modal(show ? 'show' : 'hide')
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm')
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    videoCall () {
      if (!this.appointment) return

      this.$router.push({ name: 'ApptCall', params: { id: this.appointment.id } })
    },
    getInvoiceStatus (appointment) {
      if (!appointment || !appointment.invoice) return false
      let stt = appointment.invoice.status
      let statuses = {}
      statuses[1] = { text: this.$t('schedule_page.lbl_paysts_unpaid'), class: 'text--red' }
      statuses[2] = { text: this.$t('schedule_page.lbl_paysts_wait_confirm'), class: 'text--yellow' }
      statuses[3] = { text: this.$t('schedule_page.lbl_paysts_paid'), class: 'text--green' }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    duration (s, e) {
      let start = window.moment(s)
      let end = window.moment(e)
      var duration = window.moment.duration(end.diff(start))
      return duration.asMinutes()
    },
    goToConsu (appointment) {
      if (!appointment) return
      if (appointment.consultation) {
        this.$router.push({ path: `/doctor/consultation/${appointment.consultation.id}` })
      } else {
        this.$router.push({ path: `/doctor/consultation-create?aid=${appointment.id}` })
      }
    },
    openCreateMb (appointment) {
      if (appointment.examination_status === 0 || appointment.examination_status === 1) {
        this.DialogVisibleStatus = true
      } else {
        if (!appointment) return
        if (appointment.consultation) {
          this.$refs.ModalViewConsultation.openDialogView(appointment.consultation)
        } else {
          if (appointment.health_record) {
            this.$refs.PhieuKhamBenhDetail.openDialog('createConsultation')
          } else {
            this.$refs.ModalSelectHR.show(true)
          }
        }
      }
    },
    openMsgStatus () {
      this.ApptStatus(2)
      this.DialogVisibleStatus = false
      if (!this.appointment) return
      if (this.appointment?.consultation) {
        this.$refs.ModalViewConsultation.openDialogView(this.appointment?.consultation)
      } else {
        if (this.appointment?.health_record) {
          this.$refs.PhieuKhamBenhDetail.openDialog('createConsultation')
        } else {
          this.$refs.ModalSelectHR.show(true)
        }
      }
    },
    onOpenCreateMb (hr) {
      this.selectedHr = hr
      setTimeout(() => {
        this.$refs.ModalAddConsultation.openDialogConsultation()
      }, 100)
    },
    getPersonInfoString (p) {
      return this.getGender(p.gender) + ', ' + this.formatDateDMY(p.birthday) + ' (' + this.getAges(p.birthday) + ')' + (p.career ? ', ' + p.career : '')
    },
    getGender (gender = 1) {
      return gender === 1 ? this.$t('schedule_page.lbl_male') : gender === 2 ? this.$t('schedule_page.lbl_female') : gender === 3 ? this.$t('schedule_page.lbl_other') : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    setState (value) {
      return [0, 1].includes(value) ? this.$t('schedule_page.lbl_sts_wait') : value === 2 ? this.$t('schedule_page.lbl_sts_examining') : value === 3 ? this.$t('schedule_page.lbl_sts_wait_result') : value === 4 ? this.$t('schedule_page.lbl_sts_wait_result') : value === 5 ? this.$t('schedule_page.lbl_sts_finished') : this.$t('schedule_page.lbl_cancel_visit')
    },
    getStatusAppt (status) {
      if (status === undefined || status === null) return ''
      return this.statusAppt[status]
    }
  }
}
</script>
<style lang="css">
#appointment_detail_img {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.appointment-person-info .person-name {
  padding-left: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.appointment-person-info .person-avatar .avatar {
  --tblr-avatar-size: 64px;
}
.header-title {
  width: calc(100% - 32px);
}
.btn-c {
  background-color: #20419b;
  color: white;
  border: none;
}
.btn-d {
  background-color: #1a80fd;
  color: white;
  border: none;
}
</style>