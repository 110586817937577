<template>
  <div class="container p-3 bg-white mt-2">
    <div>
      <div class="flex items-center">
        <svg class="mr-2" width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.929 0.513672C3.38878 6.15524 16.4973 17.5962 25.5317 23.7009C26.6551 24.4601 27.1008 25.9063 26.56 27.1496C25.8904 28.6891 24.0114 29.275 22.628 28.3239C15.5767 23.4762 5.21649 14.727 5.27258 8.02048C5.19956 5.97696 6.22861 1.61467 10.929 0.513672Z"
            fill="#20419B" />
          <path
            d="M17.184 0.513672C21.8844 1.61467 22.9135 5.97696 22.8405 8.02048C22.8644 10.8785 20.9965 14.1076 18.2992 17.2476L14.5073 13.7757C19.0631 8.84622 21.4891 3.73471 17.184 0.513672Z"
            fill="#20419B" />
          <path
            d="M9.71676 18.3111C7.34152 20.3235 4.83637 22.177 2.58419 23.699C1.46081 24.4582 1.0122 25.9063 1.553 27.1496C2.22259 28.6891 4.10353 29.2737 5.48688 28.3226C7.98329 26.6063 10.8942 24.401 13.6108 21.9706L9.71676 18.3111Z"
            fill="#20419B" />
          <path
            d="M14.2149 2.50039C11.4744 2.50039 9.76682 3.72025 9.25562 4.33017C9.84515 0.764442 12.8075 0.00854894 14.2149 0.0763187V2.50039Z"
            fill="#20419B" />
          <path
            d="M14.1966 2.50039C16.9371 2.50039 18.6447 3.72025 19.1559 4.33017C18.5663 0.764442 15.604 0.00854894 14.1966 0.0763187V2.50039Z"
            fill="#20419B" />
        </svg>
        <span class="txt-grey-900 page-title mr-4">{{ $t('schedule_page.lbl_schedule') }}</span>

        <div class="flex items-center">
          <p class="fs-14 txt-pri mb-0 mr-1">{{ $t('schedule_page.lbl_select_date') }}</p>
          <el-date-picker v-model="date" type="date" class="w-135px bg-white mx-1" :clearable="false" format="dd/MM/yyyy"
            @change="changeDate">
          </el-date-picker>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-8">
          <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <a class="navbar-brand d-lg-none" href="#"><img src="../../../public/assets/images/icon/appointment.svg"
                alt="" class="mr-2 page-icon" /><span
                class="txt-pri fs-14 fs-16 fw-500">{{ $t('schedule_page.lbl_working_schedule') }}</span></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item" :class="type == 'my' ? 'active' : ''">
                  <a href="javascript:;" class="nav-link fs-16 fw-500" @click="changeType('my')">{{
                    $t("schedule_page.lbl_my_appt") }}</a>
                </li>
                <li class="nav-item" :class="type == 'clinic' ? 'active' : ''">
                  <a href="javascript:;" class="nav-link fs-16 fw-500" @click="changeType('clinic')">{{
                    $t("schedule_page.lbl_workspace_appt") }}</a>
                </li>
                <!-- <li class="nav-item" :class="type=='history-off' ? 'active' : ''">
                    <a href="javascript:;" class="nav-link fs-16 fw-500" @click="changeType('history-off')">Lịch sử đến
                      khám</a>
                  </li>
                  <li class="nav-item" :class="type=='history-on' ? 'active' : ''">
                    <a href="javascript:;" class="nav-link fs-16 fw-500" @click="changeType('history-on')">Lịch sử video
                      call</a>
                  </li> -->
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-lg-4 flex align-items-center justify-content-end mt-1 mt-lg-0">
          <!-- <button
            class="btn bg-pri text-white fs-18 mr-1 border-0"
            type="button"
            @click="exportExcel"
          >
            Export
          </button> -->
          <button
            class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
            type="button" @click="onAddNewAppt()">
            <span class="fa fa-plus mr-2"></span> {{ $t('schedule_page.btn_create') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card radius-10 border-0">
      <div>
        <div class="flex items-center justify-end">
          <!-- <div class="col-lg-8 flex justify-content-end items-center mb-2 mb-lg-0">
            <p class="fs-14 txt-pri mb-0 mr-1">Theo ngày</p>

            <el-date-picker
              v-model="date"
              type="date"
              class="w-135px bg-white mx-1"
              :clearable="false"
              format="dd/MM/yyyy"
              @change="changeDate"
            >
            </el-date-picker>
          </div> -->
          <div class="w-1/3">
            <div class="input-group border bg-white rounded-xl border-gray-300">
              <span class="input-group-text bg-white border-0 radius-10 font-size-20 cursor-pointer"
                @click="getAppointments()">
                <i class="fa fa-search"></i>
              </span>
              <input type="search" v-model="search" class="form-control bg-white h-10 mr-2"
                :placeholder="$t('schedule_page.lbl_msg_search')" @keyup.enter="getAppointments()"
                @keyup.delete="onSearchDelete" />
              <span class="input-group-text bg-white border-0 radius-10 cursor-pointer" :class="!search ? 'hidden' : ''"
                @click="(search = ''), getAppointments()">
                <i class="fa fa-times-circle"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="min-h50">
          <div class="table-responsive" v-loading="loading">
            <table class="table table-vcenter table-mobile-xl card-table table-scroll">
              <thead class="d-none d-xl-table">
                <tr>
                  <th class="pl-md-2">
                    <span class="txt-pri fs-14">{{ $t('schedule_page.lbl_time') }}</span>
                  </th>
                  <th><span class="txt-pri fs-14">{{ $t('schedule_page.lbl_consultant_room') }}</span></th>
                  <th>
                    <span class="txt-pri fs-14">{{ $t('schedule_page.lbl_patient_info') }}</span>
                  </th>
                  <th><span class="txt-pri fs-14">{{ $t('schedule_page.lbl_type') }}</span></th>
                  <th><span class="txt-pri fs-14">{{ $t('schedule_page.lbl_invoice') }}</span></th>
                  <th><span class="txt-pri fs-14">{{ $t('schedule_page.lbl_status') }}</span></th>
                </tr>
              </thead>
              <tbody v-show="appointments">
                <tr v-for="appointment in appointments" :key="appointment.id" class="mt-2 mb-2 cursor-pointer"
                  @click="goToDetail(appointment)">
                  <td :data-label="$t('schedule_page.lbl_schedule')" style="text-transform: capitalize" :set="[
                    (time = moment(appointment.start_time)),
                    (end = moment(appointment.end_time)),
                  ]" class="pl-md-2">
                    <div class="d-flex align-items-center fw-500">
                      <!-- <span class="fs-16 border-right pr-2 d-block">{{
                        time.format("DD/MM/YY")
                      }}</span> -->
                      <span class="pl-2 fs-16 d-block">{{ time.format("HH:mm") }} -
                        {{ end.format("HH:mm") }}</span>
                    </div>
                  </td>
                  <td :data-label="$t('schedule_page.lbl_consultant_room')" class="table-td-name-avatar pl-2">
                    <p class="fs-16 mb-0 fw-500">
                      {{
                        appointment.consulting_room &&
                        appointment.consulting_room.name
                      }}
                    </p>
                    <p class="txt-grey-700 fs-16 mb-0">
                      {{
                        appointment.consulting_room &&
                        appointment.consulting_room.address
                      }}
                    </p>
                  </td>
                  <td :data-label="$t('schedule_page.lbl_patient_info')" class="table-td-name-avatar pl-2">
                    <div class="d-flex py-1 align-items-center" v-if="appointment.person">
                      <div style="min-width: 40px">
                        <span v-if="hasAvatar(appointment, true)" class="avatar avatar-sm avatar-rounded" :style="
                          'background-image: url(' +
                          getImageURL(appointment.person.user.avatar) +
                          ')'
                        "></span>
                        <span v-else class="avatar avatar-sm avatar-rounded bg-secondary text-white">
                          {{
                            appointment.person && appointment.person.name
                            ? appointment.person.name.charAt(0)
                            : ""
                          }}
                        </span>
                      </div>
                      <div class="flex-fill">
                        <p class="p-0 m-0 fs-16">
                          {{ appointment.person ? appointment.person.name : ""
                          }}{{
  appointment.person
  ? " - " + appointment.person.phone
  : ""
}}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td :data-label="$t('schedule_page.lbl_type')">
                    <div class="d-flex align-items-center">
                      <img v-if="appointment.method == 2" src="../../../public/assets/images/icon/blue-video.svg" alt=""
                        width="28" height="28" />
                      <img v-if="appointment.method == 1" src="../../../public/assets/images/icon/green-walking.svg"
                        alt="" width="28" height="28" />
                      <span class="fs-16 fw-500 d-block ml-2" :class="
                        appointment.method == 1
                          ? 'text--green'
                          : appointment.method == 2
                            ? 'txt-pri'
                            : ''
                      ">
                        {{
                          appointment.method == 1
                          ? $t('schedule_page.lbl_type_workspace')
                          : appointment.method == 2
                            ? $t('schedule_page.lbl_type_online')
                            : ""
                        }}
                      </span>
                    </div>
                  </td>
                  <td :data-label="$t('schedule_page.lbl_invoice')">
                    <div class="d-flex align-items-center">
                      <span v-if="
                        appointment &&
                        appointment.invoice &&
                        appointment.invoice.status === 3
                      " class="fs-16 fw-500 d-block ml-2">
                        {{ $t('schedule_page.lbl_paysts_paid') }}
                      </span>
                      <span v-if="
                        appointment &&
                        appointment.invoice &&
                        appointment.invoice.status === 1
                      " class="fs-16 fw-500 d-block ml-2 text-red-500">
                        {{ $t('schedule_page.lbl_paysts_unpaid') }}
                      </span>
                    </div>
                  </td>
                  <td :data-label="$t('schedule_page.lbl_status')">
                    <span class="txt-grey-900 px-2 py-1 rounded-2xl" :class="
                      [0, 1].includes(appointment.examination_status)
                        ? 'bg-blue-300'
                        : appointment.examination_status === 2
                          ? 'bg-green-500'
                          : appointment.examination_status === 3
                            ? 'bg-yellow-500'
                            : appointment.examination_status === 4
                              ? 'bg-yellow-500'
                              : appointment.examination_status === 5
                                ? 'bg-yellow-500'
                                : 'bg-gray-400'
                    ">{{ [0, 1].includes(appointment.examination_status)
  ? $t('schedule_page.lbl_sts_wait')
  : appointment.examination_status === 2
    ? $t('schedule_page.lbl_sts_examining')
    : appointment.examination_status === 3
      ? $t('schedule_page.lbl_sts_wait_result')
      : $t('schedule_page.lbl_sts_finished') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!appointments && !loading" class="row">
            <div class="col-md-4 offset-md-4 pt-3 pb-5 text-center">
              <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="" />
              <p class="fs-16 text-black">
                {{
                  search
                  ? this.$t('schedule_page.lbl_tb_has_msg')
                  : this.$t('schedule_page.lbl_tb_nodata_msg')
                }}
              </p>
              <button class="btn-xl btn--blue d-none" type="button" @click="onAddNewAppt()">
                <span class="fa fa-plus mr-2"></span> {{ $t('schedule_page.lbl_add_appts') }}
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-content-end py-4">
          <el-pagination v-if="
            appointmentPaging &&
            appointmentPaging.last_page &&
            appointmentPaging.last_page > 1
          " background layout="prev, pager, next" :total="appointmentPaging.total"
            :page-size="parseInt(appointmentPaging.per_page)" @current-change="handleCurrentPage">
          </el-pagination>
        </div>
      </div>
    </div>
    <ModalAssignDoctorToAppt ref="ModalAssignDoctorToAppt" @refresh="getAppointments()" />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import { ButtonHoDo } from '@/components/Button'
import { writeFile, utils } from 'xlsx'
import SearchInput from '../../components/SearchInput'
import AppointmentTable from '../../components/Appointment/AppointmentTable'
import Pagination from '../../components/Pagination'
import VideocallReportTable from '../../components/VideoCall/VideocallReportTable.vue'
import ModalAssignDoctorToAppt from '../../components/Appointment/ModalAssignDoctorToAppt.vue'
import moment from 'moment'
export default {
  name: 'AppointmentManagement',
  components: {
    SearchInput,
    AppointmentTable,
    Pagination,
    VideocallReportTable,
    ButtonHoDo,
    ModalAssignDoctorToAppt
  },
  data () {
    return {
      loading: false,
      search: '',
      appointments: [],
      appointmentPaging: [],
      type: this.$route.query.type ? this.$route.query.type : '',
      date: '',
      filter_stat: 0,
      moment,
      apptToEdit: null,
      user: this.$user,
      valuee: 1
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getAppointments()
      },
      deep: true,
      immediate: true
    }
  },
  created () { },
  computed: {},
  mounted () {
    window.scroll(0, 0)
    if (
      !this.type ||
      (this.type !== 'my' &&
        this.type !== 'clinic' &&
        this.type !== 'history-on' &&
        this.type !== 'history-off')
    ) {
      this.type = 'my'
    }
    let date = moment()
    if (this.$route.query.date) {
      try {
        date = moment.unix(this.$route.query.date)
      } catch (error) {
        date = moment()
      }
    }
    this.date = date
    this.$router
      .push({ query: { type: this.type, date: this.date.unix() } })
      .catch(() => { })
    this.getAppointments()
    setTimeout(() => {
      let user = this.$user
      let clinic = this.$globalClinic
      this.sendAmplitude('Open Appointment List', {
        'doctor id':
          user && user.doctor && user.doctor.id ? user.doctor.id : '',
        'doctor name': user && user.name ? user.name : '',
        'workspace id': clinic && clinic.id ? clinic.id : '',
        'workspace name': clinic && clinic.name ? clinic.name : '',
        timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
      })
    }, 100)
  },
  methods: {
    setState (value) {
      return [0, 1].includes(value)
        ? 'Chờ khám'
        : value === 2
          ? 'Đang khám'
          : value === 3
            ? 'Chờ Kết quả'
            : value === 4
              ? 'Chờ đọc Kết quả'
              : value === 5
                ? 'Đã khám'
                : 'Hủy lượt'
    },
    onSearchDelete () {
      this.search = ''
      this.getAppointments()
    },
    changeType (type) {
      this.appointments = {}
      this.type = type || ''
      this.$router
        .push({
          query: { type: type, date: moment(this.date).unix(), page: 1 }
        })
        .catch(() => { })
      setTimeout(() => {
        this.getAppointments()
      }, 50)
    },
    changeDate () {
      this.$router
        .push({
          query: { type: this.type, date: moment(this.date).unix(), page: 1 }
        })
        .catch(() => { })
      this.getAppointments()
    },
    onRefresh () {
      this.getAppointments()
    },
    onAddNewAppt () {
      this.$router.push({ path: '/doctor/appointment-create' })
    },
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch((_) => { })
      this.getAppointments()
    },
    getAppointments (limit) {
      let params = {
        limit: limit || 10,
        sort: 'start_time',
        sort_by: 'asc',
        page: parseInt(this.$route.query.page) || 1
      }
      if (this.search) {
        params.search = this.search
      }
      if (this.type === 'my' || this.type === 'clinic') {
        params.start_time = moment(this.date).startOf('day').toDate()
        params.end_time = moment(this.date).endOf('day').toDate()
        if (this.type === 'my') {
          params.doctor_id = this.user?.doctor?.id
          this.allAppts(params)
          return
        }
        if (this.type === 'clinic') {
          this.allAppts(params)
          return
        }
      }
      if (this.type === 'history-off') {
        params.sort_by = 'desc'
        params.end_time = this.formatTimeDate()
        params.method = 1
      }
      if (this.type === 'history-on') {
        params.sort_by = 'desc'
        params.method = 2
        params.end_time = this.formatTimeDate()
      }
    },
    // async myAppts (id, params) {
    //   let self = this
    //   this.loading = true
    //   await this.$rf.getRequest('DoctorRequest').myApptsV2(id, params).then(r => {
    //     self.appointments = r.data
    //   }).finally(() => {
    //     self.loading = false
    //   })
    // },
    async allAppts (params) {
      let self = this
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .allApptsV2(params)
        .then((r) => {
          self.appointments = r.data
          self.appointmentPaging = r.meta
        })
        .finally(() => {
          self.loading = false
        })
    },
    formatTimeDate (date_time) {
      return moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    // exportExcel () {
    //   const list = this.appointments.data.length > 0 ? this.appointments.data : null
    //   if (!list) {
    //     alert('Danh sách export không tồn tại!')
    //     return
    //   }
    //   const result = list.map((item, index) => {
    //     return {
    //       'STT': index + 1,
    //       'Ngày giờ': (item.start_time ? moment(item.start_time).format('DD/MM/YYYY HH:mm') : '') + (item.end_time ? ' - ' + moment(item.end_time).format('HH:mm') : ''),
    //       'Họ Tên': item.person ? (item.person_name + ', ' + (item.person.gender === 1 ? 'Nam, ' : item.person.gender === 2 ? 'Nữ, ' : '') + item.person.age + ' Tuổi ' + (item.person.birthday ? '(' + moment(item.person.birthday).format('YYYY') + ')' : '')) : '',
    //       'Địa chỉ': item.person && item.person.address,
    //       'HSSK': item.person_diseases && item.person_diseases.diseases_name,
    //       'Bác sĩ': item.doctor_name
    //     }
    //   })
    //   this.convertJsonToExcel(result)
    // },
    async convertJsonToExcel (result) {
      const workSheet = utils.json_to_sheet(result)
      const workBook = utils.book_new()

      utils.book_append_sheet(workBook, workSheet, 'Data')

      writeFile(workBook, `Hodo_appts_${moment().format('DD/MM/YYYY')}.xlsx`)
    },
    hasAvatar (appt, type = false) {
      if (type) {
        return appt?.person?.user?.avatar
      } else {
        return appt?.doctor?.user?.avatar
      }
    },
    getInvoiceStatus (appt) {
      if (!appt || !appt.invoice) return false
      let stt = appt.invoice.status
      let statuses = {}
      statuses[1] = {
        text: 'Chưa thanh toán',
        class: 'text--red bg--red-corl'
      }
      statuses[2] = {
        text: 'Chờ xác nhận',
        class: 'text--yellow bg--yellow-corl'
      }
      statuses[3] = {
        text: 'Đã thanh toán',
        class: 'text--green bg--green-corl'
      }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    getApptStatus (stt) {
      let statuses = []
      statuses[1] = {
        class: 'text--yellow bg--yellow-corl',
        text: 'Chờ xác nhận'
      }
      statuses[2] = {
        class: 'text--green bg--green-corl',
        text: 'Xác nhận khám'
      }
      statuses[3] = { class: 'text--red bg--red-corl', text: 'Bác sĩ từ chối' }
      statuses[4] = {
        class: 'text--yellow bg--yellow-corl',
        text: 'Chờ xác nhân'
      }
      statuses[5] = {
        class: 'text--green bg--green-corl',
        text: 'Xác nhận khám'
      }
      statuses[6] = {
        class: 'text--red bg--red-corl',
        text: 'Bệnh nhân từ chối'
      }
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    goToDetail (appt) {
      if (appt) {
        this.$router.push({
          name: 'AppointmentDetail',
          params: { id: appt.id }
        })
      }
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    onUpdate (appt) {
      setTimeout(() => {
        this.$refs.ModalAssignDoctorToAppt.show(true, appt)
      }, 100)
      window.event.stopPropagation()
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .nav-item a {
    color: #7386c7 !important;
  }

  .nav-item.active a {
    color: #20419b !important;
  }

  .navbar-expand-lg.navbar-light .nav-item.active:after {
    border-color: #20419b !important;
  }

  .min-h50 {
    min-height: 50vh;
  }

  .w-135px {
    width: 135px !important;
  }
}
</style>
