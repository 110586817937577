<template>
  <div>
    <div class="mb-2">
      <custom-filters
        :doctorId="doctorId"
        search-box-class="w-1/3"
        @setFilters="handleFilters"
        :isRefresh="refreshFilters"
        :customStatusData="customStatusData"
      />
    </div>
    <div>
      <div class="mb-2 hodo-table">
        <b-table
          id="schedule-table"
          :busy="loading"
          hover
          responsive
          sort-icon-right
          show-empty
          :empty-text="$t('Không tìm thấy dữ liệu')"
          head-variant="light"
          :fields="fields"
          :items="listData"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="Number(meta.per_page)"
          :current-page="1"
          @row-clicked="rowClickHandler"
        >
          <template #table-busy>
            <div class="text-center my-2 flex flex-col items-center py-2">
              <b-spinner class="align-middle mb-1"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(start_time)="data">
            {{
            appUtils.formatDateTime(data.item.start_time || new Date())
            }}
          </template>
          <template #cell(invoice.status)="data">
            <div
              class="flex justify-center"
              v-if="
                data.item.invoice &&
                Number(data.item.invoice.status) ===
                  INVOICE_STATUS.CODE.DOCTOR_ACCEPT
              "
            >
              <span class="text-green-700">{{ $t("Đã thanh toán") }}</span>
            </div>
            <div
              class="flex justify-center"
              v-else-if="
                data.item.invoice &&
                Number(data.item.invoice.status) ===
                  INVOICE_STATUS.CODE.PATIENT_PAID
              "
            >
              <span class="text-yellow-600">{{ $t("Chờ xác nhận") }}</span>
            </div>
            <div class="flex justify-center" v-else @click.stop>
              <el-button type="danger" size="mini">
                {{ $t("Chưa thanh toán") }}
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              </el-button>
              <!-- <el-dropdown
                @command="(value) => changeInvoicesStatus(value, data.item)"
              >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="3">{{ $t('Đã thanh toán') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>-->
            </div>
          </template>
          <!-- <template #cell(status)="data">
            <div
              class="block text-center"
              @click.stop
              :set="[
                (status = getApptStatus(
                  data.item.status,
                  data.item.examination_status
                )),
              ]"
            >
              <button
                v-if="
                  appUtils.isToday(data.item.start_time) &&
                  (Number(data.item.status) ===
                    APPOINTMENT_STATUS.CODE.CLINIC_PENDING ||
                    Number(data.item.status) ===
                      APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED) &&
                  Number(data.item.examination_status) === 0 &&
                  !doctorId
                "
                @click="onHandleCheckInUser({ item: data.item })"
                class="border-0 rounded-md bg-blue-900 text-white text-xs py-2 px-2 whitespace-nowrap"
                type="button"
              >
                <span>{{ $t("Xác nhận có mặt") }}</span>
              </button>
              <span v-else-if="!appUtils.isToday(data.item.start_time)">
                {{
                $t("Chưa đến hẹn")
                }}
              </span>
              <span
                v-if="
                  (Number(data.item.status) ===
                    APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED &&
                    Number(data.item.examination_status) === 0) ||
                  (Number(data.item.status) ===
                    APPOINTMENT_STATUS.CODE.CLINIC_PENDING &&
                    Number(data.item.examination_status) === 1) ||
                  (Number(data.item.status) ===
                    APPOINTMENT_STATUS.CODE.CLINIC_PENDING &&
                    Number(data.item.examination_status) === 0 &&
                    doctorId)
                "
                :class="status.class"
              >{{ status.text }}</span>
            </div>
          </template>-->
        </b-table>
      </div>

      <div class="flex justify-end items-center w-full">
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="5"
          :page-size="Number(meta.per_page)"
          :total="meta.total"
          class="el-pagination-mdt"
          @next-click="handleChangePage"
          @prev-click="handleChangePage"
          @current-change="handleChangePage"
          hide-on-single-page
        ></el-pagination>
        <div class="ml-1">
          <el-dropdown>
            <span
              class="block py-1.5 px-2 rounded-lg border bg-white text-gray-900 text-sm"
              role="button"
            >
              <span>{{ meta.per_page }}</span>
              <span class="mx-1">/</span>
              <span>{{ $t("Trang") }}</span>
              <i class="el-icon-caret-bottom" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in listShowRecord" :key="index">
                <div @click="handleSelectedShowRecord(item)">
                  <span>{{ item }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.hodo-table /deep/ .table th,
.hodo-table /deep/ .table td {
  cursor: pointer;
}

.hodo-table /deep/ .table th {
  color: black !important;
  background-color: #e4e7ec !important;
  font-size: 14px !important;
}

.hodo-table /deep/ .table-hover tbody tr:hover {
  background-color: #ffffff;
}

.el-pagination-mdt /deep/ {
  padding: 0;
}

.el-pagination-mdt /deep/ .number.active {
  background-color: #20419b !important;
}

.el-pagination-mdt /deep/ .number.active:hover {
  color: white !important;
}

.el-pagination-mdt /deep/ .number:hover {
  color: #20419b !important;
}
</style>

<script>
import { BTable, BPagination, BSpinner } from 'bootstrap-vue'
import CustomPagination from '../../../components/CustomPagination'
import CustomFilters from '../../../components/CustomFilters'
import { debounce } from 'lodash'
import {
  APPOINTMENT_STATUS,
  INVOICE_STATUS
  // PATIENT_RECEPTION_TYPE
} from '../../../utils/constants'
import { mapActions } from 'vuex'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'AppointmentModal',
  components: {
    BTable,
    BPagination,
    BSpinner,
    CustomPagination,
    CustomFilters
  },
  props: {
    items: {
      type: Array
    },
    metaData: {
      type: Object
    },
    refreshFilters: {
      type: Boolean,
      default: false
    },
    doctorId: {
      type: Number
    }
  },
  data () {
    return {
      loading: false,
      APPOINTMENT_STATUS,
      INVOICE_STATUS,
      listShowRecord: [10, 20, 50, 100],
      pagination: {
        perPage: 10,
        currentPage: 1
      },
      today: this.moment(),
      sortBy: 'start_time',
      sortDesc: false,
      fields: [
        {
          key: 'code',
          label: this.$t('Mã đặt lịch'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: false
        },
        {
          key: 'person_name',
          label: this.$t('Họ và tên'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: true
        },
        // { key: 'person.contact_phone', label: this.$t('SĐT'), thClass: 'text-nowrap text-sm py-1', tdClass: 'text-sm align-middle py-2', sortable: true },
        // { key: 'appt_reason', label: this.$t('Lý do khám'), thClass: 'text-nowrap text-sm py-1', tdClass: 'text-sm align-middle py-2', sortable: true },
        {
          key: 'consulting_room.name',
          label: this.$t('Phòng tư vấn'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: true
        },
        {
          key: 'doctor_name',
          label: this.$t('Bác sĩ'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: true
        },
        {
          key: 'start_time',
          label: this.$t('Thời gian'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: true
        },
        {
          key: 'invoice.status',
          label: this.$t('Thanh toán'),
          thClass: 'text-nowrap text-sm py-1',
          tdClass: 'text-sm align-middle py-2',
          sortable: true
        }
        // {
        //   key: 'status',
        //   label: this.$t('Trạng thái'),
        //   thClass: 'text-nowrap text-sm py-1',
        //   tdClass: 'text-sm align-middle py-2',
        //   sortable: true
        // }
      ],
      listData: this.items,
      meta: this.metaData || { per_page: 10, total: 1 },
      totalPages: this.metaData?.total || 0,
      appUtils,
      customStatusData: {
        options: [
          {
            value: 'All',
            label: this.$t('Tất cả')
          },
          {
            value: APPOINTMENT_STATUS.CODE.CLINIC_CONFIRMED,
            label: this.$t('Đã xác nhận')
          },
          {
            value: APPOINTMENT_STATUS.CODE.CLINIC_PENDING,
            label: this.$t('Chờ xác nhận')
          },
          {
            value: APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED,
            label: this.$t('Đã hủy')
          }
        ],
        value: APPOINTMENT_STATUS.CODE.CLINIC_PENDING
      }
    }
  },
  watch: {
    items (data) {
      this.loading = true
      if (data) {
        this.listData = data
        this.loading = false
      }
    },
    metaData (data) {
      if (data) {
        this.meta = data
        this.totalPages = data.total
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    ...mapActions('appointmentStore', [
      'updateAppointmentRequest',
      'checkInUser'
    ]),
    getApptStatus (stt, ex_stt) {
      switch (stt) {
        case APPOINTMENT_STATUS.CODE.CLINIC_PENDING:
          if (ex_stt === 1) {
            return { class: 'text-green-700', text: 'Đã xác nhận' }
          } else {
            return { class: 'text-yellow-600', text: 'Chờ xác nhận' }
          }
        case APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED:
          if (ex_stt === 1) {
            return { class: 'text-green-700', text: 'Đã xác nhận' }
          } else {
            return { class: 'text-yellow-600', text: 'Chờ xác nhận' }
          }
        case APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED:
          return { class: 'text-red-500', text: 'Đã hủy lịch hẹn' }
        default:
          return { class: 'text-yellow-600', text: 'Chờ xác nhận' }
      }
      // switch (stt) {
      //   case APPOINTMENT_STATUS.CODE.CLINIC_PENDING:
      //   case APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED:
      //     return { class: 'text-yellow-600', text: 'Chờ xác nhận' }
      //   case APPOINTMENT_STATUS.CODE.PATIENT_CANCELLED:
      //     return { class: 'text-red-500', text: 'Đã hủy lịch hẹn' }
      //   case APPOINTMENT_STATUS.CODE.CLINIC_CONFIRMED:
      //   default:
      //     return { class: 'text-yellow-600', text: 'Chờ xác nhận' }
      // }
    },
    renderPaymentStates (data) {
      switch (Number(data.item?.invoice?.status)) {
        case INVOICE_STATUS.CODE.PATIENT_PENDING:
          return `<span class="text-red-600">${this.$t(
            'Chưa thanh toán'
          )}</span>`
        case INVOICE_STATUS.CODE.PATIENT_PAID:
          return `<span class="text-yellow-600">${this.$t(
            'Chờ xác nhận'
          )}</span>`
        case INVOICE_STATUS.CODE.DOCTOR_ACCEPT:
          return `<span class="text-green-700">${this.$t(
            'Đã thanh toán'
          )}</span>`
        default:
          return `<span class="text-red-600">${this.$t(
            'Chưa thanh toán'
          )}</span>`
      }
    },
    rowClickHandler (record, index) {
      if (record) {
        // this.$router.push({
        //   name: 'AppointmentDetailV2',
        //   params: { id: record.id },
        //   query: { type: PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT }
        // })
        this.$router.push({
          name: 'PatientVisitDetail',
          params: { id: record.appt_patient_visit?.id }
          // query: { type: PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT }
        })
      }
    },
    async onHandleCheckInUser ({ item }) {
      const self = this

      if (
        this.moment(item?.start_time)
          .startOf('day')
          .toDate() -
          this.moment()
            .startOf('day')
            .toDate() !==
        0
      ) {
        self.$alert(
          self.$t('Lịch hẹn không phải ngày hôm nay'),
          self.$t('Bạn không thể xác nhận lịch hẹn này')
        )
        return
      }

      try {
        self
          .$confirm(self.$t('Bạn xác nhận bệnh nhân đã có mặt tại phòng khám?'))
          .then(async _ => {
            try {
              self.loading = true

              const paramsData = {
                clinic_id: item.clinic_id,
                cr_id: item.cr_id,
                doctor_id: item.doctor_id,
                person_id: item.person_id,
                start_time: item.start_time,
                end_time: item.end_time
              }

              await self.checkInUser({
                id: item.id,
                data: {
                  ...paramsData,
                  appt_id: item.id,
                  method: item.method,
                  visit_reason: item.appt_reason,
                  checkin_time: self.moment()
                },
                apptData: item
              })
            } catch (error) {
              console.log(error)
              self.loading = false
            } finally {
              self.$message({
                type: 'success',
                message: self.$t('Xác nhận thành công!')
              })
              self.$emit('refresh')
              self.loading = false
            }
          })
          .catch(_ => {})
      } catch (error) {
        console.log(error)
      }
    },
    handleSelectedShowRecord (value) {
      // this.pagination.perPage = value
      this.$emit('onChangePagination', { per_page: value })
    },
    handleFilters (params) {
      this.$emit('setFilters', params)
    },
    handleChangePage: debounce(function (newPage) {
      this.$emit('onChangePagination', { page: newPage })
    }, 500),
    async changeInvoicesStatus (type, item) {
      let self = this

      if (!this.can('approval-appt') || !item.invoice?.id) return

      try {
        var r = confirm('Bạn có chắc chắn không?')
        if (r) {
          var param = {
            status: ''
          }
          if (type === 1) {
            param.status = 'reject'
            await self.$rf
              .getRequest('DoctorRequest')
              .postInvoicesStatus(item.invoice.id, param)
          } else if (type === 3) {
            param.status = 'accept'
            await self.$rf
              .getRequest('DoctorRequest')
              .postInvoicesStatus(item.invoice.id, param)
            self.$toast.open({
              message: self.$t('Xác nhận thanh toán thành công'),
              type: 'success'
            })
          } else {
            self.$toast.open({
              message: 'Đang nâng cấp chức năng này',
              type: 'info'
            })
          }
          self.$emit('refresh')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
