<template>
    <div class="container">
        <HeaderWindow :title="'Đặt lịch khám'" @onClose="onClose"></HeaderWindow>
        <AppointmentForm :appt_edit="appt" v-show="step == 'input'" @nextStep="nextStep"></AppointmentForm>
        <AppointmentConfirm v-if="step == 'confirm'" :data="data_appt" @backStep="step = 'input'" @refresh="onClose"></AppointmentConfirm>
    </div>
</template>
<script>
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import AppointmentForm from '../../components/Appointment/AppointmentForm'
import AppointmentConfirm from '../../components/Appointment/AppointmentConfirm'
export default {

  name: 'UpdateAppointment',
  components: { AppointmentForm, HeaderWindow, AppointmentConfirm },
  data () {
    return {
      disease: null,
      loading: false,
      step: 'input',
      data_appt: null,
      appt: null
    }
  },
  mounted () {
    let self = this
    if (self.$route.params.id) self.getAppointment(self.$route.params.id)
  },
  methods: {
    onClose () {
      return this.$router.go(-1)
    },
    async getAppointment (id) {
      let self = this
      self.loading = true
      try {
        await self.$rf.getRequest('DoctorRequest').getAppointment(id).then(res => {
          if (res && res.data) {
            self.appt = res.data
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    nextStep (data) {
      if (!data) return
      this.data_appt = data
      this.step = 'confirm'
    }
  }
}
</script>
<style lang="css" scoped>
</style>